<!--
 * @Author: your name
 * @Date: 2021-12-06 09:46:04
 * @LastEditTime: 2021-12-09 15:02:22
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \scm-admin\src\views\financial-manage\supplier-settlement\components\auditDialog\index.vue
-->
<template>
  <div>
    <el-dialog
      :title="row.title"
      :visible.sync="dialogVisible"
      width="950px"
      center
      :close-on-click-modal="false"
    >
      <el-row class="mb-3">
        <el-col v-for="(item,index) in row.labelArr" :key="index" :span="8">{{ item.label }}：{{ item.value }}</el-col>
      </el-row>
      <pasia-table :table-data="row.tableData1" :columns="row.columns1" :width="700">
        <el-table-column slot="same" label="是否有差异" align="center" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.same==true">是</span>
            <span v-if="scope.row.same==false">否</span>
          </template>
        </el-table-column>
      </pasia-table>
      <pasia-table :table-data="row.tableData2" :columns="row.columns2">
        <el-table-column slot="ticket" label="发票号" align="center" width="150">
          <template slot-scope="scope">
            <!-- 点击查看图片 -->
            <el-button type="text" :style="customStyle(scope.row.invoiceImageUrl)" @click="viewImg(scope.row.invoiceImageUrl)">{{ scope.row.invoiceCode }}</el-button>
          </template>
        </el-table-column>
        <el-table-column slot="invoiceType" label="发票类型">
          <template slot-scope="scope">
            {{ getDicLabel('INVOICE_TYPE', scope.row.invoiceType) }}
          </template>
        </el-table-column>
      </pasia-table>
      <el-input v-model="row.remark" placeholder="备注" />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="checkLoading" @click="invoiceCkeck(1)">审核不通过</el-button>
        <el-button type="primary" :loading="checkLoading" @click="invoiceCkeck(2)">审核通过</el-button>
      </span>
    </el-dialog>
    <ShowPhoto :visible="photoVisible" :url="url" @closeClick="()=>{photoVisible=false}" />

  </div>
</template>

<script>
import PasiaTable from '@/components/Table'
import { invoiceCkeck } from '@/api/vendorPurchase-api.js'
import ShowPhoto from '@/components/ImageView'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'

export default {
  components: { PasiaTable, ShowPhoto },
  mixins: [commodityInfoDict],

  props: {
    value: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: () => {}
    }

  },
  data() {
    return {
      url: '',
      photoVisible: false,
      checkLoading: false
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    customStyle(url) {
      if (!url) {
        return { color: '#606266',
          cursor: 'default '
        }
      }
    },
    viewImg(url) {
      if (url) {
        this.url = url
        this.photoVisible = true
      }
    },
    async invoiceCkeck(status) {
      try {
        this.checkLoading = true
        const { tableData1: taxList, tableData2: invoiceList, labelArr, remark = '' } = this.row
        const { value: requestFundsCode } = labelArr.find(item => item.label === '请款单') || []
        const params = Object.assign({}, { remark, status, requestFundsCode, taxList, invoiceList })
        const { code, msg } = await invoiceCkeck(params)
        if (code === 0) {
          this.$notify({
            message: msg,
            type: 'success'
          })
          this.$emit('input', false)
          this.$emit('refresh')
        }
      } finally {
        this.checkLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
/deep/.el-input  .el-input__inner {
    width: 100%!important;
}
</style>
