<template>
  <div>
    <el-dialog
      :title="type==='pay'?'付款登记':'发票登记'"
      :visible.sync="dialogVisible"
      width="600px"
      center
      :close-on-click-modal="false"
    >
      <el-form ref="form" label-position="right" label-width="150px" :model="form" :rules="rules">
        <template v-if="type==='pay'">
          <el-form-item label="付款账户" :class="$i18n.locale" prop="paymentAccount">
            <el-select v-model="form.paymentAccount" clearable filterable>
              <el-option v-for="item in paymentList" :key="item.id" :label="item.cardNo" :value="item.cardNo" />
            </el-select>
          </el-form-item>
          <el-form-item label="付款金额" prop="paymentAmount">
            <el-input-number
              v-model="form.paymentAmount"
              :controls="false"
              :precision="2"
              :max="row.waitPaymentAmount>0?row.waitPaymentAmount:-0.01"
              :min="row.waitPaymentAmount > 0 ? 0.01:row.waitPaymentAmount"
            />
          </el-form-item>
          <el-form-item label="付款时间" prop="paymentTime">
            <el-date-picker
              v-model="form.paymentTime"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期"
              default-time="12:00:00"
            />
          </el-form-item>
          <el-form-item label="付款凭证：">
            <input ref="fileId" type="file" style="width:200px;" accept="image/*" @change="getFile"> <i
              v-if="file"
              class="el-icon-circle-close"
              @click="clearFile"
            />
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item label="发票类型" prop="invoiceType">
            <el-select v-model="form.invoiceType" filterable>
              <el-option
                v-for="item in _getAllCommodityDict('INVOICE_TYPE')"
                :key="item.val"
                :label="item.label"
                :value="item.val"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="发票号" prop="invoiceCode">
            <el-input
              v-model="form.invoiceCode"
              style="display:inline"
              oninput="value=value.replace(/[^A-Za-z0-9]/g,'')"
            />
            <el-button
              v-if="form.invoiceCode"
              ref="invoiceCode"
              type="text"
              style="display:inline"
              class="ml-2"
              @click="cope(form.invoiceCode)"
            >复制</el-button>
          </el-form-item>
          <el-form-item label="发票未税金额：" prop="invoiceNoTaxAmount">
            <el-input-number v-model="form.invoiceNoTaxAmount" :controls="false" :precision="2" />
          </el-form-item>
          <el-form-item label="发票税额：" prop="invoiceTaxAmount">
            <el-input-number v-model="form.invoiceTaxAmount" :controls="false" :precision="2" />
          </el-form-item>
          <el-form-item label="税率：" prop="taxRate">
            <el-input
              v-model="form.taxRate"
              type="number"
              oninput="value=value.replace(/[^0-9]/g,'')"
              @blur="form.taxRate=blurTaxrateNotZero($event)"
            />
          </el-form-item>
          <el-form-item label="开票日期：">
            <el-date-picker
              v-model="form.invoiceDate"
              type="datetime"
              default-time="12:00:00"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期"
            />
          </el-form-item>
          <el-form-item label="发票图片：">
            <input ref="fileId" type="file" style="width:200px;" @change="getFile"> <i
              v-if="file"
              class="el-icon-circle-close ml-1"
              @click="clearFile"
            />
          </el-form-item>
        </template>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">关闭</el-button>
        <el-button type="primary" :loading="regLoading" @click="regSubmit">登记</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { paymentRegister, invoiceRegister } from '@/api/vendorPurchase-api.js'
import { deepClone } from '@/utils'
import { validAmount } from '@/utils/validate'
import { getVendorAccountListByCargoOwnerCode } from '@/api/select'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'

export default {
  // components: { ImageUpload },
  mixins: [commodityInfoDict],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: () => { }
    },
    type: {
      type: String,
      default: () => {
        return ''
      }
    }

  },
  data() {
    /*     const validateMoney = (rule, value, callback) => {
      if (!value) {
        callback(new Error('必填'))
      } else if (!(/^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/).test(value)) {
        return callback(new Error('请输入大于0的正数'))
      } else if (value.indexOf('.') !== -1 && value.split('.').length > 2) {
        return callback(new Error('请输入正确格式'))
      } else {
        callback()
      }
    } */
    const validateInvoiceCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error('必填'))
      } else if (!(/^[0-9a-zA-Z]{1,32}$/).test(value)) {
        return callback(new Error('数字和字母，32位以内'))
      } else {
        callback()
      }
    }
    const validatePaymentAmount = (rule, value, callback) => {
      if (!value) {
        callback(new Error('必填'))
      } else {
        callback()
      }
    }
    return {
      paymentList: [],
      loading: false,
      form: {
        paymentAccount: '',
        paymentAmount: undefined,
        paymentTime: '',
        invoiceCode: '',
        invoiceTaxAmount: undefined, invoiceNoTaxAmount: undefined,
        taxRate: '',
        invoiceDate: '',
        invoiceType: '3'
      },
      file: '',
      regLoading: false,
      rules: {
        paymentAccount: [{ required: true, message: '必填', trigger: ['change'] }],
        invoiceType: [{ required: true, message: '必填', trigger: ['change'] }],
        paymentAmount: [{ required: true, message: '必填', trigger: 'blur' }, { validator: validatePaymentAmount, trigger: 'blur' }],
        paymentTime: { required: true, message: '必填', trigger: 'blur' },
        invoiceCode: [{ required: true, message: '必填', trigger: 'blur' }, { validator: validateInvoiceCode, trigger: 'blur' }],
        invoiceNoTaxAmount: [{ required: true, message: '必填', trigger: 'blur' }],
        invoiceTaxAmount: [{ required: true, message: '必填', trigger: 'blur' }],
        taxRate: [{ required: true, message: '必填', trigger: 'blur' }]
      }
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    ticketParams() {
      return {}
    },
    payParams() {
      return {}
    },
    registerParams() {
      const formDataParams = new FormData()
      const { paymentAmount, paymentTime, paymentAccount } = this.form
      const params = Object.assign({}, { paymentAccount, sysType: 1, paymentAmount, paymentTime, requestFundsCode: this.row.requestFundsCode })
      const obj = Object.assign({}, params, { file: this.file })
      Object.keys(obj).forEach(key => formDataParams.set([key], obj[key]))
      return formDataParams
    },
    invoiceParams() {
      const formDataParams = new FormData()
      const obj = deepClone(this.form)
      delete obj.paymentTime
      delete obj.paymentAmount
      const params = Object.assign({}, obj, { requestFundsCode: this.row.requestFundsCode, file: this.file })
      Object.keys(params).forEach(key => formDataParams.set([key], params[key]))
      return formDataParams
    }
  },
  watch: {
    'dialogVisible'(val) {
      if (!val) {
        this.$refs.form.resetFields()
        this.$refs.form.clearValidate()
        this.$refs.fileId.value = ''
        this.file = ''
      } else {
        this._getVendorAccountListByCargoOwnerCode()
      }
      Object.assign(this.form, this.$options.data.call(this).form)
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    async _getVendorAccountListByCargoOwnerCode() {
      const { datas } = await getVendorAccountListByCargoOwnerCode({ cargoOwnerCode: this.row.cargoOwnerCode })
      this.paymentList = datas
      this.$set(this.form, 'paymentAccount', datas.length > 0 ? datas[0].cardNo : '')
    },
    blurTaxrateNotZero(val) {
      const value = val.target.value
      const reg = /^[0-9]*$/
      if (reg.test(value)) {
        return value
      } else {
        return ''
      }
    },
    blurTaxrate(val) {
      const value = val.target.value
      // 小数
      // console.log(validAmount(value))
      const flag = value && value.indexOf('.') !== -1 && value.split('.').length === 2 && value.split('.')[1].length > 2
      if (validAmount(value)) {
        return value
      }
      if (!validAmount(value) && flag) {
        return Number(value).toFixed(2)
      }

      if (!validAmount(value)) {
        return ''
      }
    },
    cope(val) {
      if (val) {
        var input = document.createElement('input')
        input.setAttribute('readonly', 'readonly')
        input.setAttribute('value', val)
        document.body.appendChild(input)
        input.select()
        if (document.execCommand('copy')) {
          document.execCommand('copy')
        }
        document.body.removeChild(input)
      }
    },
    clearFile() {
      this.file = ''
      this.$refs.fileId.value = ''
    },
    getFile() {
      const files = this.$refs.fileId.files[0]
      this.file = files
    },
    regSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            this.regLoading = true
            if (this.type === 'pay') {
              const { code, msg } = await paymentRegister(this.registerParams)
              if (code === 0) {
                this.$notify({
                  title: msg,
                  message: msg,
                  type: 'success'
                })
                this.$emit('refresh')
                this.$emit('input', false)
              }
            } else {
              const { code, msg } = await invoiceRegister(this.invoiceParams)
              if (code === 0) {
                this.$notify({
                  title: msg,
                  message: msg,
                  type: 'success'
                })
                this.$emit('refresh')
                this.$emit('input', false)
              }
            }
          } finally {
            this.regLoading = false
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
/deep/.el-input .el-input__inner {
  width: 220px !important;
}
/deep/ .el-input-number--medium {
  width: 220px;
}
</style>
