<template>
  <div>
    <el-dialog :title="row.title" :visible.sync="dialogVisible" width="90%" center :close-on-click-modal="false">
      <el-row class="mb-3">
        <el-col v-for="(item,index) in row.labelArr" :key="index" :span="6" class="mb-1">
          {{ item.label }}：{{ item.value }}</el-col>
      </el-row>

      <pasia-table :table-data="row.tableData1" :columns="row.columns1">
        <el-table-column slot="feeSnippet" label="费用片段">
          <template slot-scope="scope">
            <div v-for="(item,index) in scope.row.feeSnippet.split(',')" :key="index"> {{ item }}</div>
          </template>
        </el-table-column>
        <el-table-column slot="settletype" label="结算类型">
          <template slot-scope="scope">
            {{ getDicLabel('FIN_SETTLEMENT_TYPE', scope.row.settlementType) }}
          </template>
        </el-table-column>
        <el-table-column slot="currency" label="币种" width="120">
          <template slot-scope="scope">
            {{ getDicLabel('CURRENCY_TYPE', scope.row.currency) }}
          </template>
        </el-table-column>
        <el-table-column slot="dcje" label="抵冲金额" width="120">
          <template slot-scope="scope">
            <el-button type="text" :style="customStyle(scope.row.auditConfirmAmount,'amount')" @click="offsetPriceDialog(scope.row)">
              {{ scope.row.offsetPrice ? scope.row.offsetPrice : 0 }}</el-button>
          </template>
        </el-table-column>
      </pasia-table>
      <pasia-table :table-data="row.tableData2" :columns="row.columns2" :width="600" class="mt-5 mb-5" />
      <pasia-table :table-data="row.tableData3" :columns="row.columns3">
        <el-table-column slot="invoiceImg" label="发票号">
          <template slot-scope="scope">
            <el-button
              type="text"
              :style="customStyle(scope.row.invoiceImageUrl,'url')"
              @click="showImg(scope.row.invoiceImageUrl)"
            >{{ scope.row.invoiceCode }}</el-button>
          </template>
        </el-table-column>
        <el-table-column slot="invoiceType" label="发票类型">
          <template slot-scope="scope">
            {{ getDicLabel('INVOICE_TYPE', scope.row.invoiceType) }}
          </template>
        </el-table-column>
      </pasia-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <ShowPhoto :visible="photoVisible" :url="url" @closeClick="()=>{photoVisible=false}" />
    <el-dialog :visible.sync="PriceDialogVisible" width="600px" max-length="150">
      <el-table :data="amountData">
        <el-table-column label="来源单号">
          <template slot-scope="scope">
            {{ scope.row.offsetSettlementCode }}
          </template>
        </el-table-column>
        <el-table-column label="抵冲金额">
          <template slot-scope="scope">
            {{ scope.row.offsetPrice }}
          </template>
        </el-table-column>

      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import PasiaTable from '@/components/Table'
import ShowPhoto from '@/components/ImageView'
import { getOffsetInfo } from '@/api/carriers.js'
export default {
  components: { PasiaTable, ShowPhoto },
  mixins: [commodityInfoDict],

  props: {
    value: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: () => { }
    }

  },
  data() {
    return {
      photoVisible: false,
      url: '',
      PriceDialogVisible: false,
      amountData: []
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    async _getOffsetInfo(data) {
      const { datas } = await getOffsetInfo(data)
      this.amountData = datas
    },
    offsetPriceDialog(row) {
      if (row.auditConfirmAmount > 0) {
        const { payableNo: purchaseSettlementCode } = row
        const { value } = this.row.labelArr.find(item => item.label === '请款单')
        this._getOffsetInfo({ purchaseSettlementCode, requestFundsCode: value })
        this.PriceDialogVisible = true
      }
    },
    showImg(url) {
      if (url) {
        this.photoVisible = true
        this.url = url
      }
    },
    customStyle(url, flag) {
      if (flag === 'url' && !url) {
        return {
          color: '#606266',
          cursor: 'default '
        }
      }
      if (flag === 'amount' && url <= 0) {
        return {
          color: '#606266',
          cursor: 'default '
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
