/*
 * @Author: your name
 * @Date: 2021-12-03 09:14:36
 * @LastEditTime: 2021-12-28 17:00:35
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \scm-admin\src\utils\tableLabel.js
 */
export const paymentDetailsColumns = [
  { slot: 'settletype' },
  { label: '来源单据', prop: 'purchaseSettlementCode' },
  { label: '结算金额', prop: 'settlementAmount' },
  { label: '待请款金额', prop: 'waitRequestFundsAmount' },
  { slot: 'currency' },
  { label: '请款金额', prop: 'requestFundsAmount' }
]

export const paymentColumns1 = [
  { label: '其中', prop: 'includes', width: 160 },
  { label: '开票金额', prop: 'invoiceAmount' },
  { label: '未税金额', prop: 'noTaxAmount' },
  { label: '增值税额', prop: 'valueAddedTaxAmount' },
  { slot: 'same' }
]

export const paymentColumns2 = [
  { slot: 'invoiceImg' },
  { label: '发票未税金额', prop: 'invoiceNoTaxAmount', width: 110 },
  { label: '发票税额', prop: 'invoiceTaxAmount' },
  { slot: 'invoiceType' },
  { label: '税率%', prop: 'taxRate' },
  { label: '开票日期', prop: 'invoiceDate' },
  { label: '登记日期', prop: 'createTime', width: 180 },
  { label: '登记人', prop: 'createByName' }
]

export const columns1 = [
  { label: '暂估应付单', prop: 'estimationPayableCode' },
  { label: '采购单', prop: 'purchaseOrderCode' },
  { label: '来源单据', prop: 'sourceCode' },
  { label: '付款类型', prop: 'paymentTypeI18' },
  { label: '单据金额', prop: 'totalPrice' },
  { label: '付款比例%', prop: 'paymentRatio' },
  { label: '付款金额', prop: 'paymentAmount' },
  { label: '未税金额', prop: 'noTaxAmount' },
  { label: '增税金额', prop: 'valueAddedTaxAmount' },
  { label: '仓库', prop: 'warehouseName' },
  { label: '对账确认日期', prop: 'reconcileConfirmTime' }
]
export const columns2 = [
  { label: '其中', prop: 'includes', width: '160px' },
  { label: '开票金额', prop: 'invoiceAmount', width: '130px' },
  { label: '未税金额', prop: 'noTaxAmount', width: '130px' },
  { label: '增值税额', prop: 'valueAddedTaxAmount', width: '130px' }
]
export const columns3 = [
  { label: '请款单', prop: 'requestFundsCode', width: '170' },
  { label: '请款时间', prop: 'createTime' },
  { label: '请款金额', prop: 'requestFundsAmount', width: 120 },
  { label: '请款人', prop: 'createByName' },
  { label: '请款审批状态', prop: 'requestFundsApproveStatusI18' },
  { label: '请款审批时间', prop: 'requestFundsApproveDate' },
  { label: '付款状态', prop: 'paymentStatusI18' },
  { label: '付款时间', prop: 'paymentTime' }

]
export const columns4 = [
  { label: '采购单', prop: 'purchaseOrderCode' },
  { label: 'SKU', prop: 'sku' },
  { label: '税率%', prop: 'taxRatio' },
  { label: '规格型号', prop: 'specification' },
  { label: 'FNSKU', prop: 'fnsku' },
  { label: 'UPC', prop: 'upc' },
  { label: '单价', prop: 'purchasePrice' },
  { label: '数量', prop: 'purchasePairs' },
  { label: '金额合计', prop: 'purchaseTotalAmount', width: 120 },
  { label: '备注', prop: 'remark' }
]

export const regColumns = [
  { slot: 'ticket' },
  { label: '发票未税金额', prop: 'invoiceNoTaxAmount', width: 110 },
  { label: '发票税额', prop: 'invoiceTaxAmount' },
  { slot: 'invoiceType' },
  { label: '税率%', prop: 'taxRate' },
  { label: '开票日期', prop: 'invoiceDate' },
  { label: '登记日期', prop: 'createTime', width: 180 },
  { label: '登记人', prop: 'createByName' }
]

export const sourceCodeColumns1 = [
  { label: '采购单号', prop: 'purchaseOrderCode' },
  { label: 'SKU', prop: 'sku' },
  { label: '税率%', prop: 'taxRatio' },
  { label: '规格型号', prop: 'specification' },
  { label: 'FNSKU', prop: 'fnsku' },
  { label: 'UPC', prop: 'upc' },
  { label: '单价', prop: 'unitPrice' },
  { label: '对账单价', prop: 'reconcilePrice' },
  { label: '数量', prop: 'collectPairs' },
  { label: '对账数量', prop: 'reconcilePairs', width: 110 },
  { label: '对账金额合计', prop: 'reconcileAmount', width: 120 },
  { label: '备注', prop: 'modifyPriceRemark' }
]

export const sourceCodeColumns2 = [
  { label: '退货单号', prop: 'returnCode' },
  { label: '采购单号', prop: 'purchaseOrderCode' },
  { label: '收货单号', prop: 'collectCode' },
  { label: 'SKU', prop: 'sku' },
  { label: '税率%', prop: 'taxRatio' },
  { label: '规格型号', prop: 'specification' },
  { label: 'FNSKU', prop: 'fnsku' },
  { label: 'UPC', prop: 'upc' },
  { label: '单价', prop: 'unitPrice' },
  { label: '数量', prop: 'returnPairs' },
  { label: '金额合计', prop: 'returnAmount', width: 120 },
  { label: '备注', prop: 'modifyPriceRemark' }
]

export const sourceCodeColumns3 = [
  { label: '采购单号', prop: 'purchaseOrderCode' },
  { label: 'Style', prop: 'style' },
  { label: 'SKU', prop: 'sku' },
  { label: '税率%', prop: 'taxRatio' },
  { label: '规格型号', prop: 'specification' },
  { label: 'FNSKU', prop: 'fnsku' },
  { label: 'UPC', prop: 'upc' },
  { label: '采购单价', prop: 'purchasePrice' },
  { label: '采购数量', prop: 'number' },
  { label: '剩余数量', prop: 'surplusPairs', width: 100 },
  { label: '采购金额', prop: 'purchaseTotalPrice', width: 120 },
  { label: '备注', prop: 'remark' }
]

// 请款弹框
export const vendorColumns = [
  { label: '结算类型', prop: 'settlementTypeI18' },
  { label: '采购结算单', prop: 'purchaseSettlementCode' },
  { label: '付款类型', prop: 'paymentTypeI18' },
  { label: '业务单据', prop: 'sourceCode' },
  { label: '税率', prop: 'taxRatio' },
  { label: '结算金额', prop: 'settlementAmount', width: 120 },
  { label: '待冲抵金额', prop: 'waitRequestFundsAmount', width: 110 },
  { label: '币种', prop: 'currency' },
  { label: '冲抵金额', prop: 'requestFundsAmount', width: 120 },
  { label: '账单截止日期', prop: 'accountPeriodDate', width: 120 }
]

export const vendorcolumns = [
  { label: '结算类型', prop: 'settlementTypeI18' },
  { label: '采购结算单', prop: 'purchaseSettlementCode' },
  { label: '付款类型', prop: 'paymentTypeI18' },
  { label: '业务单据', prop: 'sourceCode' },
  { label: '税率', prop: 'taxRatio' },
  { label: '结算金额', prop: 'settlementAmount', width: 120 },
  { label: '待请款金额', prop: 'waitRequestFundsAmount', width: 110 },
  { label: '币种', prop: 'currency' },
  { slot: 'remittance' },
  { label: '账单截止日期', prop: 'accountPeriodDate', width: 120 }
]

export const tableData1 = [
  { taxRatio: 0, waitRequestFundsAmount: -100, requestFundsAmount: -100 },
  { taxRatio: 0, waitRequestFundsAmount: -150, requestFundsAmount: -150 },
  { taxRatio: 0, waitRequestFundsAmount: -150, requestFundsAmount: -150 },
  { taxRatio: 0, waitRequestFundsAmount: -300, requestFundsAmount: -300 },
  { taxRatio: 13, waitRequestFundsAmount: -390, requestFundsAmount: -390 },
  { taxRatio: 15, waitRequestFundsAmount: -1000, requestFundsAmount: -1000 }

]
// stone付款管理
export const columns = [
  { slot: 'monney' },
  { label: '请款审批状态', prop: 'requestFundsApproveStatusI18', width: 130 },
  { label: '付款状态', prop: 'paymentStatusI18' },
  { label: '结算主体', prop: 'cargoOwnerName' },
  { label: '请款类型', prop: 'requestFundsTypeI8' },
  { label: '承运商', prop: 'vendorName' },
  { label: '请款金额', prop: 'requestFundsAmount', width: 130 },
  { label: '币种', prop: 'currency' },
  { label: '请款审批时间', prop: 'requestFundsApproveDate', width: 160 },
  { label: '待开票金额', prop: 'waitInvoiceAmount', width: 130 },
  { slot: 'ticket' },
  { label: '发票审核状态', prop: 'invoiceApproveStatusI18', width: 120 },
  { label: '待付款', prop: 'waitPaymentAmount', width: 120 },
  { slot: 'operate' }
]

export const expandColumns = [
  { label: '付款金额', prop: 'paymentAmount', width: 120 },
  { label: '付款时间', prop: 'paymentTime', width: 160 },
  { label: '登记人', prop: 'createByName', width: 110 },
  { label: '登记时间', prop: 'createTime', width: 160 },
  { slot: 'invoiceImg', width: 100 },
  { label: '付款账户', prop: 'paymentAccount', width: 150 }
]

export const ticketColumns = [
  { label: '发票号', prop: 'invoiceCode' },
  { label: '发票未税金额', prop: 'invoiceNoTaxAmount', width: 110 },
  { label: '发票税额', prop: 'invoiceTaxAmount' },
  { slot: 'invoiceType' },
  { label: '税率%', prop: 'taxRate' },
  { label: '开票日期', prop: 'invoiceDate', width: 150 },
  { label: '登记日期', prop: 'createTime', width: 160 },
  { label: '登记人', prop: 'createByName' },
  { label: '审核时间', prop: 'invoiceApproveTime' },
  { label: '发票审核状态', prop: 'invoiceApprove', width: 130 },
  { slot: 'operate' }
]

export const jimPaymentDetailsColumns = [
  { label: '费用片段', prop: 'purchaseSettlementCode' },
  { label: '结算金额', prop: 'settlementAmount' },
  { label: '待请款金额', prop: 'waitRequestFundsAmount' },
  { slot: 'currency' },
  { label: '请款金额', prop: 'requestFundsAmount' }
]

export const jimTableColumns1 = [
  { slot: 'feeSnippet' },
  { label: '应付单', prop: 'payableNo', width: 170 },
  { label: '货柜号', prop: 'containerNo' },
  { label: '应付金额', prop: 'auditConfirmAmount' },
  { label: '待请款金额', prop: 'requestPendingAmount' },
  { label: '税率%', prop: 'taxRate' },
  { label: '币种', prop: 'currency' },
  { label: '请款金额', prop: 'requestAmount' },
  { slot: 'dcje' },
  { label: '已付款金额', prop: 'paymentPrice' }
]

export const jimTableColumns2 = [
  { label: '其中', prop: 'taxRateAmountName', width: 180 },
  { label: '开票金额', prop: 'invoiceAmount' },
  { label: '未税金额', prop: 'unTaxAmount' },
  { label: '增值税额', prop: 'vat' },
  { slot: 'same' }
]

