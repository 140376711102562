<template>
  <div v-show="visible" class="showPhoto" @click="closeClick">
    <img class="img" :src="url" alt="图片加载失败">
  </div>
</template>

<script>

export default {
  props: {
    url: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeClick() { // 子组件可以使用 $emit 触发父组件的自定义事件
      this.$emit('closeClick')
    }
  }
}
</script>
<style lang="scss" scoped>
.showPhoto
    {position:fixed;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    background: rgba(0,0,0,0.5);
    z-index: 9999;
    display: flex;
    .img
        {display: block;
        margin: auto 0;
        width: 100%;
        text-align: center;}}
</style>

