
import request from '@/utils/fin-request'

// 财务付款管理分页
export function requestFundsPage(params) {
  return request({
    url: `fin-payment/requestFunds/listByPageAndPayment`,
    method: 'get',
    params
  })
}

// 付款单详情
export function requestFundsDetail(params) {
  return request({
    url: `fin-payment/requestFunds/detail`,
    method: 'get',
    params
  })
}

// 发票详情
export function invoiceDetail(params) {
  return request({
    url: `fin-payment/invoice/detail`,
    method: 'get',
    params
  })
}
//  发票审核详情
export function invoiceCheckDetail(params) {
  return request({
    url: `fin-payment/invoice/getCheckDetail`,
    method: 'get',
    params
  })
}

// 发票登记
export function invoiceRegister(data) {
  return request({
    url: `fin-payment/invoice/register`,
    method: 'post',
    data
  })
}

// 发票审核
export function invoiceCkeck(data) {
  return request({
    url: `fin-payment/invoice/check`,
    method: 'post',
    data
  })
}
// 付款记录
export function getListByCode(params) {
  return request({
    url: `fin-payment/payment/getListByCode`,
    method: 'get',
    params
  })
}

// 付款登记
export function paymentRegister(data) {
  return request({
    url: `fin-payment/payment/register`,
    method: 'post',
    data
  })
}

// 发票删除
export function deleteInvoice(data) {
  return request({
    url: `fin-payment/invoice/delete`,
    method: 'post',
    data
  })
}

// ware 对账
export function notOutBillPage(params) {
  return request({
    url: `fin-payment/bill/notOutBillPage`,
    method: 'get',
    params
  })
}

// 已出账单详情、差异确认
export function outBillDetail(id) {
  return request({
    url: `fin-payment/bill/outBillDetail/${id}`,
    method: 'get'
  })
}

// 已出账单 - 已出账单分页查询
export function outBillPage(params) {
  return request({
    url: `fin-payment/bill/outBillPage`,
    method: 'get',
    params
  })
}

// 已出账单 - 对账差异驳回 参数：id-暂估应付单ID
export function reconcileDiffReject(data) {
  return request({
    url: `fin-payment/bill/reconcileDiffReject`,
    method: 'post',
    data
  })
}

export function reconcileDiffConfirm(data) {
  return request({
    url: `fin-payment/bill/reconcileDiffConfirm`,
    method: 'post',
    data
  })
}
// 未出账单 - 出货付款预览
export function shippingPaymentView(data) {
  return request({
    url: `fin-payment/bill/shippingPaymentView`,
    method: 'post',
    data
  })
}
// 已出账单 - 账期截止日期调整
export function updateAccountPeriodTime(data) {
  return request({
    url: `fin-payment/bill/updateAccountPeriodTime`,
    method: 'post',
    data
  })
}
// 已出账单 - 供应商对账sku维度对账单
export function vendorBillBySku(id) {
  return request({
    url: `fin-payment/bill/vendorBillBySku/${id}`,
    method: 'get'
  })
}
// 已出账单 - 供应商对账style维度对账单
export function vendorBillByStyle(id) {
  return request({
    url: `fin-payment/bill/vendorBillByStyle/${id}`,
    method: 'get'
  })
}

// 已出账单 - 供应商对账数据导出
export function vendorBillExport(id) {
  return request({
    url: `fin-payment/bill/vendorBillExport/${id}`,
    method: 'get'
  })
}
// 已出账单 - 供应商对账数据导入
export function vendorReconcileImport(data) {
  return request({
    url: `fin-payment/bill/vendorReconcileImport`,
    method: 'post',
    data
  })
}
// 已出账单 - 供应商对账数据导入预览
export function vendorReconcileImportView(data) {
  return request({
    url: `fin-payment/bill/vendorReconcileImportView`,
    method: 'post',
    data
  })
}

// 已出账单-结算
export function settlement(data) {
  return request({
    url: `fin-payment/bill/settlement`,
    method: 'post',
    data
  })
}

export function shippingPaymentToPreparePayableOrder(data) {
  return request({
    url: `fin-payment/purchaseSettlement/shippingPaymentToPreparePayableOrder`,
    method: 'post',
    data
  })
}

// 采购结算分页
export function purchaseSettlement(params) {
  return request({
    url: `fin-payment/purchaseSettlement/page`,
    method: 'get',
    params
  })
}

// 请款预览
export function requestFundsView(params) {
  return request({
    url: `fin-payment/purchaseSettlement/requestFundsView`,
    method: 'get',
    params
  })
}

export function notOutBillDetail(purchaseOrderCode) {
  return request({
    url: `fin-payment/bill/notOutBillDetail/${purchaseOrderCode}`,
    method: 'get'
  })
}

// 根据id查询采购结算单
export function purchaseSettlementDetail(id) {
  return request({
    url: `fin-payment/purchaseSettlement/purchaseSettlementDetail/${id}`,
    method: 'get'
  })
}

// 请款提交
export function submit(data) {
  return request({
    url: `fin-payment/purchaseSettlement/submit`,
    method: 'post',
    data
  })
}

// 提交并请款
export function submitRequestFunds(data) {
  return request({
    url: `fin-payment/purchaseSettlement/submitRequestFunds`,
    method: 'post',
    data
  })
}

// 特殊付款校验
export function specialPaymentValid(params) {
  return request({
    url: `fin-payment/bill/specialPaymentValid`,
    method: 'get',
    params
  })
}

// 根据供应商id获取用户信息
export function getUserInfoByVendorId(data) {
  return request({
    url: `fin-payment/bill/getUserInfoByVendorId`,
    method: 'post',
    data
  })
}
// 详情页提交
export function vendorReconcileSubmit(data) {
  return request({
    url: `fin-payment/bill/vendorReconcileSubmit`,
    method: 'post',
    data
  })
}
// 特殊付款请款
export function specialPaymentSubmit(data) {
  return request({
    url: `fin-payment/bill/specialPaymentSubmit`,
    method: 'post',
    data
  })
}

// detail页面
export function vendorBillByStyleByVendorId(vendorId) {
  return request({
    url: `fin-payment/bill/vendorBillByStyleByVendorId/${vendorId}`,
    method: 'get'
  })
}

// 定时请款任务触发
export function triggerAutoRequestFunds() {
  return request({
    url: `fin-payment/basic/triggerAutoRequestFunds`,
    method: 'get'
  })
}
// 定时发邮件任务触发
export function triggerAutoSendReconcileMail() {
  return request({
    url: `fin-payment/basic/triggerAutoSendReconcileMail`,
    method: 'get'
  })
}

// 定时结算任务触发
export function triggerAutoSettlement() {
  return request({
    url: `fin-payment/basic/triggerAutoSettlement`,
    method: 'get'
  })
}

// Jim 请款单详情
export function getRequestAmountDetail(params) {
  return request({
    url: `fin-payment/logisticsAudit/getRequestAmountDetail`,
    method: 'get',
    params
  })
}
