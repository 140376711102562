<template>
  <div>
    <el-form ref="form" :model="form" label-width="150px" style="display: flex">
      <div class="el-lt" style="width: 85%">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item label="请款单号" :class="$i18n.locale">
              <el-input v-model="form.requestFundsCode" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="结算主体" :class="$i18n.locale">
              <el-select v-model="form.cargoOwnerCode" value-key="id" placeholder="请选择" filterable clearable>
                <el-option
                  v-for="item in cargoOwnerNameList"
                  :key="item.cargoOwnerName"
                  :label="item.cargoOwnerName"
                  :value="item.cargoOwnerCode"
                />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="承运商" :class="$i18n.locale">
              <el-select v-model="form.vendorId" filterable clearable :placeholder="$t('page.selectPlaceholder')">
                <el-option v-for="item in vendorOptions" :key="item.id" :label="item.vendorName" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="付款状态" :class="$i18n.locale">
              <el-select v-model="form.paymentStatus" value-key="id" clearable filterable>
                <el-option
                  v-for="item in _getAllCommodityDict('FIN_PAYMENT_STATUS')"
                  :key="item.val"
                  :label="item.label"
                  :value="item.val"
                />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="请款审批状态" :class="$i18n.locale">
              <el-select v-model="form.requestFundsApproveStatus" value-key="id" clearable filterable>
                <el-option
                  v-for="item in _getAllCommodityDict('FIN_FR_APPROVE_STATUS')"
                  :key="item.val"
                  :label="item.label"
                  :value="item.val"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="发票审批状态" :class="$i18n.locale">
              <el-select v-model="form.invoiceApproveStatus" value-key="id" clearable filterable>
                <el-option
                  v-for="item in _getAllCommodityDict('FIN_INVOICE_APPROVE_STATUS')"
                  :key="item.val"
                  :label="item.label"
                  :value="item.val"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="请款类型" :class="$i18n.locale">
              <el-select v-model="form.requestType" value-key="id" clearable filterable>
                <el-option label="物流请款" :value="2" />
                <el-option label="关税请款" :value="3" />
                <el-option label="预充值请款" :value="4" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="请款审批日期">
              <el-date-picker
                v-model="form.value1"
                class="date"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />
            </el-form-item>
          </el-col>

        </el-row>
        <el-row :span="24" class="mb-3">
          <el-col :span="24">
            <el-button type="primary" @click="showPayReg(multipleSelectionTable[0],'invoice')">发票登记</el-button>
            <el-button type="primary" @click="showTicketReg(multipleSelectionTable[0])">发票审核</el-button>
          </el-col>
        </el-row>
      </div>
      <el-row class="el-rt" style="width: 25%">
        <el-col class="row-center">
          <el-button type="primary" :loading="queryLoading" @click="queryClick(1)">{{
            $t("page.search")
          }}</el-button>
          <el-button @click="resetClick()">{{ $t("page.reset") }}</el-button>
          <el-button type="text" @click="showRow=!showRow">{{
            showRow ? $t("page.hide") : $t("page.expand")
          }}</el-button>
        </el-col>
      </el-row>
    </el-form>
    <pasia-table
      ref="table"
      :table-data="tableData"
      :max-height="'600px'"
      :columns="columns"
      :expand="true"
      :single-check="true"
      :expand-columns="expandColumns"
      :page-obj="pager"
      @handleSelectionChange="val => {multipleSelectionTable=val}"
    >
      <el-table-column slot="invoiceImg" label="付款凭证" align="center" width="100">
        <template slot-scope="scope">
          <el-button v-if="scope.row.paymentVoucherUrl" type="text" @click="showImg(scope.row.paymentVoucherUrl)">查看
          </el-button>
        </template>
      </el-table-column>
      <el-table-column slot="monney" label="请款单号" align="center" min-width="200">
        <template slot-scope="scope">
          <el-button type="text" @click="showPayCodeDialog(scope.row)">{{ scope.row.requestFundsCode }}</el-button>
        </template>
      </el-table-column>
      <el-table-column slot="ticket" label="发票张数" align="center" width="100">
        <template slot-scope="scope">
          <el-button type="text" @click="showTicketDetail(scope.row.requestFundsCode)">{{ scope.row.invoiceNum }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column slot="operate" label="操作" align="center" fixed="right" width="100">
        <template slot-scope="scope">
          <!-- 请款审批状态为审批通过允许操作，校验不通过给出提示 -->
          <el-button
            v-if="scope.row.requestFundsApproveStatus+''==='3'&&scope.row.waitPaymentAmount"
            type="text"
            @click="showPayReg(scope.row,'pay')"
          >付款</el-button>
        </template>
      </el-table-column>
    </pasia-table>
    <div class="block">
      <Paging :pager="pager" end @update="pagerUpdate" />
    </div>
    <purchaseDialog v-model="dialogVisible" :row="row" />
    <auditDialog v-model="dialogAuditVisible" :row="row" @refresh="queryClick" />
    <registrationDialog v-model="dialogRegVisible" :row="row" :type="type" @refresh="queryClick" />
    <!-- 发票 -->
    <el-dialog
      title="
      发票详情"
      :visible.sync="ticketDialogVisible"
      width="80%"
      center
    >
      <el-row style="display:flex;" class="mb-2">
        <el-col :span="8">请款单：{{ row.requestFundsCode }}</el-col>
        <el-col :span="8">结算主体：{{ row.cargoOwnerName }}</el-col>
        <el-col :span="8">供应商：{{ row.vendorName }}</el-col>
      </el-row>
      <pasia-table :table-data="row.invoiceList" :columns="ticketColumns">
        <el-table-column slot="invoiceType" label="发票类型">
          <template slot-scope="scope">
            {{ getDicLabel('INVOICE_TYPE', scope.row.invoiceType) }}
          </template>
        </el-table-column>
        <el-table-column slot="operate" label="操作" align="center" width="80">
          <template slot-scope="scope">
            <!-- 审批通过、审批不通过状态数据不允许删除 -->
            <el-button
              v-if="scope.row.invoiceApproveStatus+''==='1'||scope.row.invoiceApproveStatus+''==='2'"
              type="text"
              @click="delTicket(scope.row)"
            >删除</el-button>
          </template>
        </el-table-column>
      </pasia-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ticketDialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <ShowPhoto :visible="photoVisible" :url="url" @closeClick="()=>{photoVisible=false}" />

  </div>
</template>

<script>
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import PasiaTable from '@/components/Table'
import Paging from '@/components/Paging'
import purchaseDialog from '../components/purchaseDialog'
import registrationDialog from '../components/registrationDialog'
import auditDialog from '../components/auditDialog'
import { expandColumns, ticketColumns, columns, regColumns, paymentDetailsColumns, paymentColumns2, paymentColumns1, jimTableColumns1, jimTableColumns2 } from '@/utils/tableLabel'
import { getcargoownerList, getVendorListByVendorType } from '@/api/select.js'
import { deleteInvoice, requestFundsPage, requestFundsDetail, invoiceDetail, invoiceCheckDetail, getRequestAmountDetail } from '@/api/vendorPurchase-api.js'
import { deepClone } from '@/utils'
import ShowPhoto from '@/components/ImageView'

export default {
  components: { PasiaTable, Paging, purchaseDialog, registrationDialog, auditDialog, ShowPhoto },
  mixins: [commodityInfoDict],

  data() {
    return {
      photoVisible: false,
      url: '',
      multipleSelectionTable: [],
      dialogAuditVisible: false,
      dialogRegVisible: false,
      ticketDialogVisible: false,
      dialogVisible: false,
      pager: {
        total: 0,
        current: 1,
        size: 20
      },
      columns,
      expandColumns,
      ticketColumns,
      tableData: [],
      queryLoading: false,
      vendorOptions: [],
      showRow: false,
      form: {
        value1: [],
        pageType: 0
        // requestFundsCode: 'QK1220220216482491'
      },
      row: {
        title: '',
        labelArr: [],
        columns1: []
      },
      type: '',
      cargoOwnerNameList: []
    }
  },
  computed: {
    queryPageParams() {
      const obj = deepClone(this.form)
      const { value1 } = obj
      const [startTime = '', endTime = ''] = value1 || []
      delete obj.value1
      return Object.assign({}, obj, this.pager, { startTime, endTime },)
    }
  },
  created() {

  },
  mounted() {
    this._queryVendorList()
    this.queryClick()
    this._getcargoownerList()
  },
  methods: {
    customStyle(url) {
      if (!url) {
        return {
          color: '#606266',
          cursor: 'default '
        }
      }
    },
    showImg(url) {
      if (url) {
        this.photoVisible = true
        this.url = url
      }
    },
    async _getcargoownerList() {
      const { datas } = await getcargoownerList()
      this.cargoOwnerNameList = datas
    },
    async showTicketDetail(requestFundsCode) {
      const { code, datas } = await invoiceDetail({ requestFundsCode })
      if (code === 0) {
        datas.invoiceList = datas.invoiceList.map(item => {
          return { ...item, invoiceApprove: this.getDicLabel('FIN_INVOICE_APPROVE_STATUS', item.invoiceApproveStatus) }
        })
        // console.log(datas.invoiceList)
        this.row = datas
        this.ticketDialogVisible = true
      }
    },
    async delTicket({ id: invoiceId }) {
      const { msg, code } = await deleteInvoice({ invoiceId, requestFundsCode: this.row.requestFundsCode })
      if (code === 0) {
        this.$notify({
          message: msg,
          type: 'success'
        })
        this.queryClick()
        this.ticketDialogVisible = false
      }
    },
    getlabelArr(a, b) {
      const labelArr = []
      a.map((item, index) => {
        b.map((e, i) => {
          if (i === index) {
            labelArr.push({ label: item, value: this.row[e] })
          }
        })
      })
      return labelArr
    },
    async queryClick(type) {
      try {
        this.queryLoading = true
        type && type === 1 ? this.pager.current = 1 : ''
        const { code, datas: { records, pager }} = await requestFundsPage(this.queryPageParams)
        if (code === 0) {
          this.tableData = records.map(item => {
            const requestFundsTypeI8 = item.requestFundsType === 2 ? '物流请款' : item.requestFundsType === 3 ? '关税请款' : item.requestFundsType === 4 ? '预充值请款' : '采购请款'
            return { ...item, expandData: item.paymentLogList, requestFundsTypeI8 }
          })
          /*           if (!this.form.requestType) {
            this.tableData = this.tableData.filter(item => [2, 3, 4].includes(item.requestFundsType))
          } */
          this.pager = pager
        }
      } finally {
        this.queryLoading = false
        this.$refs.table.clearSingleCheck()
      }
    },
    resetClick() {
      this.form = this.$options.data().form
      this.queryClick(1)
    },
    showPayReg(row, type) {
      if (type === 'invoice') {
        if (this.multipleSelectionTable.length === 1 && row.waitInvoiceAmount && row.invoiceApproveStatus + '' !== '3') {
          this.type = type
          this.row = row
          this.dialogRegVisible = true
        } else {
          this.$message.warning('请勾选一条存在开票金额，且发票审核状态为【审批通过】时不允许操作')
          return
        }
      } else {
        this.type = type
        this.row = row
        this.dialogRegVisible = true
      }
    },
    async _queryVendorList() {
      const { datas } = await getVendorListByVendorType({ vendorType: '6' })
      this.vendorOptions = datas
    },
    pagerUpdate(val) {
      this.pager = val
      this.queryClick()
    },
    async showPayCodeDialog({ requestFundsCode, requestFundsType }) {
      if (['2', '3', '4'].includes(requestFundsType + '')) {
        //  Jim联调
        const { datas, datas: { taxAmountVOList = [], invoiceRegisterList = [], auditItemVOList = [] }} = await getRequestAmountDetail({ requestAmountNo: requestFundsCode })
        this.row = Object.assign({}, datas, {
          requestFundsApproveStatusI18: this.getDicLabel('FIN_FR_APPROVE_STATUS', datas.requestFundsApproveStatus),
          invoiceApproveStatusI18: this.getDicLabel('FIN_INVOICE_APPROVE_STATUS', datas.invoiceApproveStatus),
          paymentStatusI18: this.getDicLabel('FIN_PAYMENT_STATUS', datas.paymentStatus)
        })
        const label = ['请款单', '结算主体', '承运商', '结算总金额', '未税总金额', '增值税总额', '请款审核状态', '发票审核状态', '付款状态', '币种']
        const value = ['requestAmountNo', 'settleEntity', 'carrier', 'settlementAmount', 'unTaxAmount', 'vatamount', 'requestFundsApproveStatusI18', 'invoiceApproveStatusI18', 'paymentStatusI18', 'currency']
        this.row = Object.assign({}, {
          title: '请款单详情', tableData1: auditItemVOList,
          tableData2: taxAmountVOList.map(item => { return { ...item, same: item.isDifference } }), tableData3: invoiceRegisterList, columns3: paymentColumns2
        }, { labelArr: this.getlabelArr(label, value), columns1: jimTableColumns1, columns2: jimTableColumns2 },
        )
      } else {
        const { datas, datas: { billTaxAmountVOList: taxList, invoiceRegisterVOList: invoiceList, requestFundsDetailVOList: prepareRequestFundsList }} = await requestFundsDetail({ requestFundsCode })
        this.row = datas
        const label = ['请款单', '结算主体', '供应商', '结算总金额', '未税总金额', '增值税总额', '请款审核状态', '发票审核状态', '付款状态', '币种']
        const value = ['requestFundsCode', 'cargoOwnerName', 'vendorName', 'settlementAmount', 'noTaxAmount', 'valueAddedTaxAmount', 'requestFundsApproveStatusI18', 'invoiceApproveStatusI18', 'paymentStatusI18', 'currency']

        this.row = Object.assign({}, {
          title: '请款单详情', tableData1: prepareRequestFundsList,
          tableData2: taxList.map(item => { return { ...item, includes: `涉及${item.taxRatio}税率结算金额` } }), tableData3: invoiceList, columns3: paymentColumns2
        }, { labelArr: this.getlabelArr(label, value), columns1: paymentDetailsColumns, columns2: paymentColumns1 })
      }
      this.dialogVisible = true
    },
    async showTicketReg(row) {
      const { requestFundsCode = '', invoiceApproveStatus = '' } = row || {}
      if (invoiceApproveStatus + '' !== '2' || this.multipleSelectionTable.length !== 1) {
        this.$message.warning('请勾选一条【待审核】数据')
        return
      }
      const { datas, datas: { taxList, invoiceList }} = await invoiceCheckDetail({ requestFundsCode })
      this.row = datas
      this.type = 'ticket'
      const label = ['请款单', '结算主体', '供应商']
      const value = ['requestFundsCode', 'cargoOwnerName', 'vendorName']
      this.row = Object.assign({}, {
        tableData1: taxList.map(item => { return { ...item, includes: `涉及${item.taxRatio}税率结算金额` } }), tableData2: invoiceList, title: '发票审核'
      }, { labelArr: this.getlabelArr(label, value), columns1: paymentColumns1, columns2: regColumns })
      this.dialogAuditVisible = true
    }
  }

}
</script>

<style scoped lang="scss">
.date {
  /deep/.el-range-input {
    width: 200px !important;
  }
}
/deep/ .el-table__header-wrapper .el-checkbox {
  //找到表头那一行，然后把里面的复选框隐藏掉
  display: none;
}
</style>
